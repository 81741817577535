import { FC } from 'react';
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';

import { api } from '../services/api.service';
import { useAuth } from '../contexts/auth';
import GuardAuth from '../guards/GuardAuth';
import GuardGuest from '../guards/GuardGuest';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import AuthLayout from '../views/auth/AuthLayout';
import DashboardLayout from '../views/dashboard/DashboardLayout';

// Components
import Loadable from '../components/Loadable';

// Auth
const AuthLogin = Loadable(() => import('../views/auth/AuthLogin'));
const AuthEmailChange = Loadable(() => import('../views/auth/AuthEmailChange'));
const AuthPasswordForgotten = Loadable(() => import('../views/auth/AuthPasswordForgotten'));
const AuthPasswordChange = Loadable(() => import('../views/auth/AuthPasswordChange'));
const AuthRegister = Loadable(() => import('../views/auth/AuthRegister'));
const AuthActivate = Loadable(() => import('../views/auth/AuthActivate'));

// Admin
const AdminCompanies = Loadable(() => import('../views/admin/AdminCompanies'));
const AdminCompany = Loadable(() => import('../views/admin/AdminCompany'));
const AdminCompanyAdd = Loadable(() => import('../views/admin/AdminCompanyAdd'));
const AdminCycles = Loadable(() => import('../views/admin/AdminCycles'));
const AdminCyclesAdd = Loadable(() => import('../views/admin/AdminCyclesAdd'));
const AdminCyclesEdit = Loadable(() => import('../views/admin/AdminCyclesEdit'));
const AdminManage = Loadable(() => import('../views/admin/AdminManage'));
const AdminManageContent = Loadable(() => import('../views/admin/AdminManageContent'));
const AdminManageEmail = Loadable(() => import('../views/admin/AdminManageEmail'));
const AdminManageQuestionnaire = Loadable(() => import('../views/admin/AdminManageQuestionnaire'));
const AdminManageQuestionnaireSection = Loadable(
  () => import('../views/admin/AdminManageQuestionnaireSection')
);
const AdminManageQuestionnaireQuestion = Loadable(
  () => import('../views/admin/AdminManageQuestionnaireQuestion')
);
const AdminManageQuestionnaireOption = Loadable(
  () => import('../views/admin/AdminManageQuestionnaireOption')
);
const AdminManageQuestionnaireFeedback = Loadable(
  () => import('../views/admin/AdminManageQuestionnaireFeedback')
);
const AdminStats = Loadable(() => import('../views/admin/AdminStats'));
const AdminStatsOverview = Loadable(() => import('../views/admin/AdminStatsOverview'));
const AdminStatsDetail = Loadable(() => import('../views/admin/AdminStatsDetail'));
const AdminStatsMaterials = Loadable(() => import('../views/admin/AdminStatsMaterials'));

// Shared
const Contact = Loadable(() => import('../views/shared/Contact'));
const Support = Loadable(() => import('../views/shared/Support'));
const Users = Loadable(() => import('../views/shared/Users'));
const UserEdit = Loadable(() => import('../views/shared/UserEdit'));
const UserAdd = Loadable(() => import('../views/shared/UserAdd'));

// User
const UserDashboard = Loadable(() => import('../views/user/UserDashboard'));
const UserOrganization = Loadable(() => import('../views/user/UserOrganization'));
const UserProfile = Loadable(() => import('../views/user/UserProfile'));
const UserProfileChangeEmail = Loadable(() => import('../views/user/UserProfileChangeEmail'));
const UserQuestionary = Loadable(() => import('../views/user/UserQuestionary/UserQuestionary'));
const UserQuestionaryDetail = Loadable(
  () => import('../views/user/UserQuestionary/UserQuestionaryDetail')
);
const UserQuestionarySubmit = Loadable(
  () => import('../views/user/UserQuestionary/UserQuestionarySubmit')
);
const UserQuestionaryResult = Loadable(
  () => import('../views/user/UserQuestionary/UserQuestionaryResult')
);

// General
const NotFound = Loadable(() => import('../views/NotFound'));

const Router: FC = () => {
  const { logout } = useAuth();

  api.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      if (error?.response?.status === 403) {
        return logout();
      }

      return Promise.reject(error);
    }
  );

  return useRoutes([
    {
      path: 'auth',
      element: (
        <GuardGuest>
          <AuthLayout />
        </GuardGuest>
      ),
      children: [
        {
          path: 'inloggen',
          element: <AuthLogin />
        },
        {
          path: 'wachtwoord-vergeten',
          element: <AuthPasswordForgotten />
        },
        {
          path: 'hoofdgebruiker-registreren',
          element: <AuthRegister />
        },
        {
          path: 'account-activeren',
          element: <AuthActivate />
        },
        {
          path: 'wachtwoord-instellen',
          element: <AuthPasswordChange />
        },
        {
          path: 'emailadres-wijzigen',
          element: <AuthEmailChange />
        }
      ]
    },

    // Dashboard routes
    {
      path: '/',
      element: (
        <GuardAuth>
          <DashboardLayout />
        </GuardAuth>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard" replace /> },
        {
          path: '/dashboard',
          element: (
            <RoleBasedGuard accessibleRoles={['company_main_user', 'company_user']}>
              <UserDashboard />
            </RoleBasedGuard>
          )
        },
        {
          path: '/vragenlijst',
          children: [
            {
              path: '/vragenlijst/:questionnaireId',
              element: (
                <RoleBasedGuard accessibleRoles={['company_main_user', 'company_user']}>
                  <UserQuestionary />
                </RoleBasedGuard>
              )
            },
            {
              path: '/vragenlijst/:questionnaireId/vragen',
              element: (
                <RoleBasedGuard accessibleRoles={['company_main_user', 'company_user']}>
                  <UserQuestionaryDetail />
                </RoleBasedGuard>
              )
            },
            {
              path: '/vragenlijst/:questionnaireId/indienen',
              element: (
                <RoleBasedGuard accessibleRoles={['company_main_user', 'company_user']}>
                  <UserQuestionarySubmit />
                </RoleBasedGuard>
              )
            },
            {
              path: '/vragenlijst/:questionnaireId/resultaat',
              element: (
                <RoleBasedGuard accessibleRoles={['company_main_user', 'company_user']}>
                  <UserQuestionaryResult />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: '/mijn-organisatie',
          element: (
            <RoleBasedGuard accessibleRoles={['company_main_user', 'company_user']}>
              <UserOrganization />
            </RoleBasedGuard>
          )
        },
        {
          path: '/kennisbank',
          element: (
            <RoleBasedGuard
              accessibleRoles={['super_admin', 'admin', 'company_main_user', 'company_user']}
            >
              <Support />
            </RoleBasedGuard>
          )
        },
        {
          path: '/contact',
          element: (
            <RoleBasedGuard
              accessibleRoles={['super_admin', 'admin', 'company_main_user', 'company_user']}
            >
              <Contact />
            </RoleBasedGuard>
          )
        },
        {
          path: '/mijn-profiel',
          children: [
            {
              path: '/mijn-profiel',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['super_admin', 'admin', 'company_main_user', 'company_user']}
                >
                  <UserProfile />
                </RoleBasedGuard>
              )
            },
            {
              path: '/mijn-profiel/emailadres-wijzigen',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['super_admin', 'admin', 'company_main_user', 'company_user']}
                >
                  <UserProfileChangeEmail />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: '/bedrijven',
          children: [
            {
              path: '/bedrijven',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminCompanies />
                </RoleBasedGuard>
              )
            },
            {
              path: '/bedrijven/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminCompany />
                </RoleBasedGuard>
              )
            },
            {
              path: '/bedrijven/bedrijf-toevoegen',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminCompanyAdd />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: '/jaargangen',
          children: [
            {
              path: '/jaargangen',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminCycles />
                </RoleBasedGuard>
              )
            },
            {
              path: '/jaargangen/jaargang-toevoegen',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminCyclesAdd />
                </RoleBasedGuard>
              )
            },
            {
              path: '/jaargangen/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminCyclesEdit />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: '/statistieken',
          element: (
            <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
              <AdminStats />
            </RoleBasedGuard>
          ),
          children: [
            { path: '/statistieken', element: <Navigate to="/statistieken/overzicht" replace /> },
            {
              path: '/statistieken/overzicht',
              element: <AdminStatsOverview />
            },
            {
              path: '/statistieken/:id',
              element: <AdminStatsDetail />
            }
          ]
        },
        {
          path: '/statistieken/grondstoffen',
          element: (
            <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
              <AdminStatsMaterials />
            </RoleBasedGuard>
          )
        },
        {
          path: '/gebruikers',
          children: [
            {
              path: '/gebruikers',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin', 'company_main_user']}>
                  <Users />
                </RoleBasedGuard>
              )
            },
            {
              path: '/gebruikers/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin', 'company_main_user']}>
                  <UserEdit />
                </RoleBasedGuard>
              )
            },
            {
              path: '/gebruikers/gebruiker-toevoegen',
              element: (
                <RoleBasedGuard accessibleRoles={['company_main_user']}>
                  <UserAdd />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: '/beheer',
          children: [
            {
              path: '/beheer',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminManage />
                </RoleBasedGuard>
              )
            },
            {
              path: '/beheer/content/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminManageContent />
                </RoleBasedGuard>
              )
            },
            {
              path: '/beheer/email-templates/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminManageEmail />
                </RoleBasedGuard>
              )
            },
            {
              path: '/beheer/vragenlijsten/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminManageQuestionnaire />
                </RoleBasedGuard>
              )
            },
            {
              path: '/beheer/vragenlijsten/:id/:sectionId',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminManageQuestionnaireSection />
                </RoleBasedGuard>
              )
            },
            {
              path: '/beheer/vragenlijsten/:id/:sectionId/vraag/:questionId',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminManageQuestionnaireQuestion />
                </RoleBasedGuard>
              )
            },
            {
              // 'subvraag' was added to distinguish sub-questions i.e. hide the tooltip WYSIWYG
              path: '/beheer/vragenlijsten/:id/:sectionId/vraag/:questionId/subvraag',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminManageQuestionnaireQuestion />
                </RoleBasedGuard>
              )
            },
            {
              path: '/beheer/vragenlijsten/:id/:sectionId/vraag/:questionId/:optionId',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminManageQuestionnaireOption />
                </RoleBasedGuard>
              )
            },
            {
              path: '/beheer/vragenlijsten/:id/:sectionId/feedback/:feedbackId',
              element: (
                <RoleBasedGuard accessibleRoles={['super_admin', 'admin']}>
                  <AdminManageQuestionnaireFeedback />
                </RoleBasedGuard>
              )
            }
          ]
        }
      ]
    },

    // General
    {
      path: '*',
      element: (
        <GuardGuest>
          <AuthLayout />
        </GuardGuest>
      ),
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
};

export { Router, BrowserRouter as Provider };
