import axios, { AxiosResponse } from 'axios';
import { QueryFunctionContext } from 'react-query';
import { pick, pickBy } from 'lodash';

import {
  AccountActivate,
  AccountPasswordChange,
  AccountPasswordReset,
  AccountRegister,
  Answer,
  ContentTextSubmit,
  Company,
  CycleAdd,
  EmailTemplateSubmit,
  Login,
  RefreshToken,
  UpdateProfile,
  ProfileChangeEmailRequest,
  ProfileChangeEmailConfirm,
  User,
  QuestionnaireOptionPut,
  QuestionnaireSectionPut,
  QuestionnaireQuestionPut
} from '../types';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true
});

const requestHelper = {
  async delete<TResponse>(url: string, params?: any) {
    return api.delete<TResponse>(url, { ...params });
  },
  async get<TResponse>(url: string, params?: any) {
    return api.get<TResponse>(url, { ...params });
  },
  async post<TResponse>(url: string, payload: any): Promise<AxiosResponse<TResponse>> {
    return api.post(url, payload);
  },
  async put<TResponse>(url: string, payload: any): Promise<AxiosResponse<TResponse>> {
    return api.put(url, payload);
  }
};

export const apiService = {
  async login(payload: Login): Promise<AxiosResponse<RefreshToken>> {
    return requestHelper.post('/login_check', payload);
  },
  async getProfile() {
    const { data } = await requestHelper.get('/v1/profile');
    return data;
  },
  async companiesDelete(id: string) {
    const { data } = await requestHelper.delete(`/v1/companies/${id}`);
    return data;
  },
  async companiesSendMail(recipients: string) {
    const { data } = await requestHelper.post('/v1/companies/send-mail', { recipients });
    return data;
  },
  async companiesSendRegisterMail(subset: string) {
    const { data } = await requestHelper.post('/v1/companies/send-register-mail', { subset });
    return data;
  },
  async companiesSendRegisterMailIndividual(id: string) {
    const { data } = await requestHelper.post(`/v1/companies/${id}/send-register-mail`, {});
    return data;
  },
  async contentTextsAllGet() {
    const { data } = await requestHelper.get('/v1/content-texts-all');
    return data;
  },
  async contentTextsGet() {
    const { data } = await requestHelper.get('/v1/content-texts');
    return data;
  },
  async contentTextsDetailGet({ queryKey }: QueryFunctionContext) {
    const [, id] = queryKey;
    const { data } = await requestHelper.get(`/v1/content-texts/${id}`);
    return data;
  },
  async contentTextsPut(id: string, payload: ContentTextSubmit) {
    const { data } = await requestHelper.put(`/v1/content-texts/${id}`, payload);
    return data;
  },
  async cyclesAdd(payload: CycleAdd): Promise<AxiosResponse> {
    return requestHelper.post('/v1/cycles', payload);
  },
  async cyclesUpdate(id: string, payload: CycleAdd): Promise<AxiosResponse> {
    return requestHelper.put(`/v1/cycles/${id}`, payload);
  },
  async cyclesParticipationAssignCompanies(id: string) {
    const { data } = await requestHelper.post(`/v1/cycles/${id}/participations`, {});
    return data;
  },
  async cyclesSendMail(id: string, subset: string) {
    const { data } = await requestHelper.post(`/v1/cycles/${id}/send-mail`, { subset });
    return data;
  },
  async emailTemplatesGet() {
    const { data } = await requestHelper.get('/v1/email-templates');
    return data;
  },
  async emailTemplatesDetailGet({ queryKey }: QueryFunctionContext) {
    const [, id] = queryKey;
    const { data } = await requestHelper.get(`/v1/email-templates/${id}`);
    return data;
  },
  async emailTemplatesPut(id: string, payload: EmailTemplateSubmit) {
    const { data } = await requestHelper.put(`/v1/email-templates/${id}`, payload);
    return data;
  },
  async feedbackTextsGet({ queryKey }: QueryFunctionContext) {
    const [, id] = queryKey;
    const { data } = await requestHelper.get(`/v1/feedback-texts/${id}`);
    return data;
  },
  async feedbackTextsPut(id: string, payload: QuestionnaireOptionPut) {
    const { data } = await requestHelper.put(`/v1/feedback-texts/${id}`, payload);
    return data;
  },
  async getCompany({ queryKey }: QueryFunctionContext) {
    const [, id] = queryKey;
    const { data } = await requestHelper.get(`/v1/companies/${id}`);
    return data;
  },
  async getCompanies() {
    const { data } = await requestHelper.get('/v1/companies');
    return data;
  },
  async getCompaniesAuxData() {
    const { data } = await requestHelper.get('/v1/companies-aux-data');
    return data;
  },
  async getCompaniesParticipations({ queryKey }: QueryFunctionContext) {
    const [, id] = queryKey;
    const { data } = await requestHelper.get(`/v1/companies/${id}/participations`);
    return data;
  },
  async getCycle({ queryKey }: QueryFunctionContext) {
    const [, id] = queryKey;
    const { data } = await requestHelper.get(`/v1/cycles/${id}`);
    return data;
  },
  async getCycleParticipations({ queryKey }: QueryFunctionContext) {
    const [, id] = queryKey;
    const { data } = await requestHelper.get(`/v1/cycles/${id}/participations`);
    return data;
  },
  async getCycles() {
    const { data } = await requestHelper.get('/v1/cycles');
    return data;
  },
  async getEntry({ queryKey }: QueryFunctionContext) {
    const [, id] = queryKey;
    const { data } = await requestHelper.get(`/v1/entries/${id}`);
    return data;
  },
  async updateEntryAnswers(id: string, payload: Answer[]): Promise<AxiosResponse> {
    return requestHelper.put(`/v1/entries/${id}/answers`, { answers: payload });
  },
  async submitEntry(id: string, payload = null): Promise<AxiosResponse> {
    return requestHelper.put(`/v1/entries/${id}/submit`, payload);
  },
  async addParticipationEntry(id: string, payload: null): Promise<AxiosResponse> {
    return requestHelper.post(`/v1/participations/${id}/entries`, payload);
  },
  async getUsers() {
    const { data } = await requestHelper.get('/v1/users');
    return data;
  },
  async getUser({ queryKey }: QueryFunctionContext) {
    const [, id] = queryKey;
    const { data } = await requestHelper.get(`/v1/users/${id}`);
    return data;
  },
  async updateUser(payload: User, id: string) {
    const { data } = await requestHelper.put(`/v1/users/${id}`, payload);
    return data;
  },
  async addUser(payload: User): Promise<AxiosResponse> {
    return requestHelper.post('/v1/users', payload);
  },
  async userResendActivation(id: string, payload = null): Promise<AxiosResponse> {
    return requestHelper.post(`/v1/users/${id}/send-enable-mail`, payload);
  },
  async updateCompany(payload: Company, id: string) {
    const { data } = await requestHelper.put(`/v1/companies/${id}`, payload);
    return data;
  },
  async addCompany(payload: Company): Promise<AxiosResponse> {
    return requestHelper.post('/v1/companies', payload);
  },
  async updateProfile(payload: UpdateProfile): Promise<AxiosResponse> {
    return requestHelper.put('/v1/profile', payload);
  },
  async profileChangeEmailRequest(payload: ProfileChangeEmailRequest): Promise<AxiosResponse> {
    return requestHelper.post('/v1/profile/send-change-email-mail', payload);
  },
  async profileChangeEmailConfirm(payload: ProfileChangeEmailConfirm): Promise<AxiosResponse> {
    return requestHelper.post('/v1/profile/change-email', payload);
  },
  async register(payload: AccountRegister): Promise<AxiosResponse> {
    return requestHelper.post('/v1/account/register', payload);
  },
  async activate(payload: AccountActivate): Promise<AxiosResponse> {
    return requestHelper.post('/v1/account/enable', payload);
  },
  async resetPassword(payload: AccountPasswordReset): Promise<AxiosResponse> {
    return requestHelper.post('/v1/account/send-reset-password-mail', payload);
  },
  async accountPasswordChange(payload: AccountPasswordChange): Promise<AxiosResponse> {
    return requestHelper.post('/v1/account/reset-password', payload);
  },
  async tokenRefresh(payload: RefreshToken): Promise<AxiosResponse<RefreshToken>> {
    return requestHelper.post('/token/refresh', payload);
  },
  async statsAuxData() {
    const { data } = await requestHelper.get('/v1/stats-aux-data');
    return data;
  },
  async statsFiltered({ queryKey }: QueryFunctionContext) {
    const [, questionnaireType, cycleId, sectorId, subSectorId, companySize, sectionId] = queryKey;
    const params = { questionnaireType, cycleId, sectorId, subSectorId, companySize, sectionId };

    // only use params whose value is not null
    const { data } = await requestHelper.get(`/v1/stats`, {
      params: pickBy(
        pick(params, [
          'questionnaireType',
          'cycleId',
          'sectorId',
          'subSectorId',
          'companySize',
          'sectionId'
        ])
      )
    });
    return data;
  },
  async statsRawMaterials({ queryKey }: QueryFunctionContext) {
    const [
      ,
      type,
      questionnaireType,
      cycleId,
      sectorId,
      subSectorId,
      companySize,
      rawMaterialId,
      countryId,
      top5,
      sectionId
    ] = queryKey;
    const params = {
      type,
      questionnaireType,
      cycleId,
      sectorId,
      subSectorId,
      companySize,
      rawMaterialId,
      countryId,
      top5,
      sectionId
    };

    // only use params whose value is not null
    const { data } = await requestHelper.get(`/v1/stats-raw-materials`, {
      params: pickBy(
        pick(params, [
          'type',
          'questionnaireType',
          'cycleId',
          'sectorId',
          'subSectorId',
          'companySize',
          'rawMaterialId',
          'countryId',
          'top5',
          'sectionId'
        ])
      )
    });
    return data;
  },
  async questionnairesGet() {
    const { data } = await requestHelper.get('/v1/questionnaires');
    return data;
  },
  async questionnaireGet({ queryKey }: QueryFunctionContext) {
    const [, id] = queryKey;
    const { data } = await requestHelper.get(`/v1/questionnaires/${id}`);
    return data;
  },
  async questionnaireOptionGet({ queryKey }: QueryFunctionContext) {
    const [, id] = queryKey;
    const { data } = await requestHelper.get(`/v1/questionnaire-question-options/${id}`);
    return data;
  },
  async questionnaireOptionPut(id: string, payload: QuestionnaireOptionPut) {
    const { data } = await requestHelper.put(`/v1/questionnaire-question-options/${id}`, payload);
    return data;
  },
  async questionnaireSectionGet({ queryKey }: QueryFunctionContext) {
    const [, id] = queryKey;
    const { data } = await requestHelper.get(`/v1/questionnaire-sections/${id}`);
    return data;
  },
  async questionnaireSectionPut(id: string, payload: QuestionnaireSectionPut) {
    const { data } = await requestHelper.put(`/v1/questionnaire-sections/${id}`, payload);
    return data;
  },
  async questionnaireQuestionGet({ queryKey }: QueryFunctionContext) {
    const [, id] = queryKey;
    const { data } = await requestHelper.get(`/v1/questionnaire-questions/${id}`);
    return data;
  },
  async questionnaireQuestionPut(id: string, payload: QuestionnaireQuestionPut) {
    const { data } = await requestHelper.put(`/v1/questionnaire-questions/${id}`, payload);
    return data;
  },
  async userDelete(id: string) {
    const { data } = await requestHelper.delete(`/v1/users/${id}`);
    return data;
  },
  async userDeleteInvite(id: string) {
    const { data } = await requestHelper.delete(`/v1/user-invites/${id}`);
    return data;
  }
};
