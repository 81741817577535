import { FC } from 'react';

import Breadcrumb from '../components/Breadcrumb';

const NoPermission: FC = ({ children }) => (
  <>
    <Breadcrumb title="Geen toestemming" />
    <div className="flex flex-col p-10 space-y-12">{children}</div>
  </>
);

export default NoPermission;
