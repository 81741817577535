import { FC, ReactNode } from 'react';

import { useAuth } from '../contexts/auth';
import NoPermission from '../views/NoPermission';

type RoleBasedGuardProp = {
  accessibleRoles: string[];
  children: ReactNode | string;
};

const useCurrentRole = (): string | undefined => {
  const { user } = useAuth();
  return user?.effectiveRole;
};

const RoleBasedGuard: FC<RoleBasedGuardProp> = ({
  accessibleRoles,
  children
}: RoleBasedGuardProp) => {
  const currentRole = useCurrentRole();

  if (!currentRole || !accessibleRoles.includes(currentRole)) {
    return (
      <NoPermission>U lijkt geen toestemming te hebben om deze pagina te bekijken.</NoPermission>
    );
  }

  return <>{children}</>;
};

export default RoleBasedGuard;
