import { FC } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';

import { Router, Provider as RouterProvider } from './router';
import { AuthProvider } from './contexts/auth';
import ErrorBoundary from './utils/ErrorBoundary';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <RouterProvider>
      <ErrorBoundary fallback="Er ging iets mis">
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-4JQXW6HNWR"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-4JQXW6HNWR');
            `}
          </script>
        </Helmet>
        <AuthProvider>
          <Router />
        </AuthProvider>
        <Toaster position="top-right" />
      </ErrorBoundary>
    </RouterProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default App;
