import { FC, ReactNode } from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';

import { useAuth } from '../contexts/auth';

interface HeaderProps {
  children: ReactNode;
}

const Header: FC<HeaderProps> = ({ children }) => {
  const { user } = useAuth();

  const getInitials = () => {
    const firstCharacter = user?.firstName.charAt(0);
    const lastCharacter = user?.lastName.charAt(0);

    if (!firstCharacter || !lastCharacter) {
      return '?';
    }

    return `${firstCharacter}${lastCharacter}`;
  };

  return (
    <header className="flex flex-col mb-8 bg-white 2xl:mb-16">
      <div className="container flex flex-col items-center justify-between py-4 space-y-4 2xl:py-6 md:flex-row md:space-y-0">
        <Logo />
        {user && (
          <div className="flex items-center space-x-3">
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-tertiary-100 md:h-14 md:w-14 text-tertiary-400">
              <div className="text-sm md:text-base">{getInitials()}</div>
            </div>
            <div className="text-tertiary-400">{user?.fullName}</div>
          </div>
        )}
      </div>
      {children}
    </header>
  );
};

export default Header;
