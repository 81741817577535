import { FC } from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbRoute {
  name: string;
  path: string;
}
interface BreadcrumbProps {
  routes?: BreadcrumbRoute[];
  title: string | undefined;
}

const defaultProps = {
  routes: []
};

const Breadcrumb: FC<BreadcrumbProps> = ({ routes, title }) => (
  <div className="flex flex-row items-center h-10 bg-gray-100">
    <div className="flex items-center h-full p-4 bg-white text-primary-500 | breadcrumb-arrow breadcrumb-arrow-home">
      <svg
        aria-hidden="true"
        focusable="false"
        className="w-4 h-4"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
      >
        <path
          fill="currentColor"
          d="M570.69 236.28L512 184.45V48a16 16 0 0 0-16-16h-64a16 16 0 0 0-16 16v51.69L314.75 10.31a39.85 39.85 0 0 0-53.45 0l-256 226a16 16 0 0 0-1.21 22.6L25.5 282.7a16 16 0 0 0 22.6 1.21L277.42 81.63a16 16 0 0 1 21.17 0L527.91 283.9a16 16 0 0 0 22.6-1.21l21.4-23.82a16 16 0 0 0-1.22-22.59zM288 115L69.47 307.71c-1.62 1.46-3.69 2.14-5.47 3.35V496a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V368a16 16 0 0 1 16-16h96a16 16 0 0 1 16 16v128a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V311.1c-1.7-1.16-3.72-1.82-5.26-3.2z"
        />
      </svg>
    </div>
    {routes &&
      routes.map((route: BreadcrumbRoute) => (
        <Link
          className="px-5 py-2 pl-8 bg-gray-200 hover:bg-primary-300 hover:text-gray-100 text-primary-500 | breadcrumb-arrow"
          key={route.path}
          to={route.path}
        >
          {route.name}
        </Link>
      ))}
    <div className="px-5 py-2 pl-8 text-white bg-primary-500 | breadcrumb-arrow">{title}</div>
  </div>
);

Breadcrumb.defaultProps = defaultProps;

export default Breadcrumb;
