import { memo } from 'react';

const Loader = memo(() => (
  <div className="loader">
    <div className="loader-container">
      <div className="loader-animation">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
    <div className="loader-text">Laden...</div>
  </div>
));

Loader.displayName = 'Loader';

export default Loader;
