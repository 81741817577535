import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

const AuthLayout: FC = () => {
  return (
    <div className="bg-primary-100">
      <Header>
        <div className="py-9 bg-primary-500" />
      </Header>
      <main className="container">
        <div className="flex flex-col">
          <Outlet />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AuthLayout;
