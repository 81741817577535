import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseMutateFunction, useMutation, useQuery } from 'react-query';
import { toast } from 'react-hot-toast';

// General
import { apiService } from '../services/api.service';
import { Login, User } from '../types';

interface AuthContextData {
  user: User | null;
  login: UseMutateFunction<string | null, unknown, Login, unknown>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: FC = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  /**
   * Get user info, and refresh when token has changed.
   */
  useQuery('getProfile', apiService.getProfile, {
    onSuccess: (data: User) => {
      setUser(data);
    },
    enabled: !!token,
    retry: false
  });

  /**
   * Handle user login.
   */
  const { mutate: login } = useMutation(
    async (payload: Login) => {
      const { data: response } = await apiService.login(payload);
      return response.refresh_token;
    },
    {
      onSuccess: (refreshToken) => {
        if (!refreshToken) throw Error;

        localStorage.setItem('accessToken', refreshToken);

        toast.success('Welkom!');

        navigate('/dashboard');

        window.dispatchEvent(new Event('local-storage'));
      },
      onError: () => {
        toast.error('Er ging iets mis bij het inloggen');
      }
    }
  );

  /**
   * Handle user logout.
   */
  const logout = () => {
    localStorage.removeItem('accessToken');

    setToken(null);

    toast.success('U bent uitgelogd');

    setUser(null);

    return navigate('/auth/inloggen');
  };

  useEffect(() => {
    function checkAccessToken() {
      const item = localStorage.getItem('accessToken');

      if (item) {
        setToken(item);
      }
    }

    checkAccessToken();

    window.addEventListener('local-storage', checkAccessToken);

    return () => {
      window.removeEventListener('local-storage', checkAccessToken);
    };
  }, []);

  return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>;
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
