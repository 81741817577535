import { ReactElement, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

type GuardGuestProps = {
  children: ReactNode;
};

export default function GuardGuest({ children }: GuardGuestProps): ReactElement {
  const accessToken = window.localStorage.getItem('accessToken');
  const location = useLocation();

  // Change e-mail address route also needs to be accessed by people which are logged in
  if (location.pathname === '/auth/emailadres-wijzigen' || location.pathname === '/404') {
    return <>{children}</>;
  }

  if (accessToken) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
}
