import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { useAuth } from '../contexts/auth';

export const Navigation: FC = () => {
  const { logout, user } = useAuth();
  const linkClass = (isActive: boolean) => `text-white ${isActive ? 'font-semibold' : null}`;
  const currentRole = user?.effectiveRole || '';

  const handleLogout = () => logout();

  return (
    <nav className="py-6 bg-primary-500">
      <div className="container flex flex-col flex-wrap space-y-4 lg:flex-row lg:justify-between lg:space-y-0">
        <ul className="flex flex-col flex-wrap space-y-4 lg:flex-row lg:space-x-6 lg:space-y-0">
          <li>
            <NavLink className={({ isActive }) => linkClass(isActive)} to="/dashboard">
              Dashboard
            </NavLink>
          </li>
          {['company_main_user'].includes(currentRole) && (
            <li>
              <NavLink className={({ isActive }) => linkClass(isActive)} to="/gebruikers">
                Gebruikers
              </NavLink>
            </li>
          )}
          <li>
            <NavLink className={({ isActive }) => linkClass(isActive)} to="/mijn-organisatie">
              Mijn organisatie
            </NavLink>
          </li>
          <li>
            <NavLink className={({ isActive }) => linkClass(isActive)} to="/kennisbank">
              Kennisbank
            </NavLink>
          </li>
          <li>
            <NavLink className={({ isActive }) => linkClass(isActive)} to="/contact">
              Contact
            </NavLink>
          </li>
        </ul>
        <ul className="flex flex-col flex-wrap space-y-4 lg:flex-row lg:space-x-6 lg:space-y-0">
          <li>
            <NavLink className={({ isActive }) => linkClass(isActive)} to="/mijn-profiel">
              Mijn profiel
            </NavLink>
          </li>
          <li>
            <button className="text-white" onClick={handleLogout}>
              Uitloggen
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export const NavigationAdmin: FC = () => {
  const { logout } = useAuth();
  const linkClass = (isActive: boolean) => `text-white ${isActive ? 'font-semibold' : null}`;

  const handleLogout = () => logout();

  return (
    <nav className="py-6 bg-primary-500">
      <div className="container flex flex-col flex-wrap space-y-4 lg:flex-row lg:justify-between lg:space-y-0">
        <ul className="flex flex-col flex-wrap space-y-4 lg:flex-row lg:space-x-6 lg:space-y-0">
          <li>
            <NavLink className={({ isActive }) => linkClass(isActive)} to="/bedrijven">
              Bedrijven
            </NavLink>
          </li>
          <li>
            <NavLink className={({ isActive }) => linkClass(isActive)} to="/jaargangen">
              Jaargangen
            </NavLink>
          </li>
          <li>
            <NavLink className={({ isActive }) => linkClass(isActive)} to="/gebruikers">
              Gebruikers
            </NavLink>
          </li>
          <li>
            <NavLink className={({ isActive }) => linkClass(isActive)} to="/statistieken">
              Statistieken
            </NavLink>
          </li>
          <li>
            <NavLink className={({ isActive }) => linkClass(isActive)} to="/beheer">
              Beheer
            </NavLink>
          </li>
          <li>
            <NavLink className={({ isActive }) => linkClass(isActive)} to="/kennisbank">
              Kennisbank
            </NavLink>
          </li>
          <li>
            <NavLink className={({ isActive }) => linkClass(isActive)} to="/contact">
              Contact
            </NavLink>
          </li>
        </ul>
        <ul className="flex flex-col flex-wrap space-y-4 lg:flex-row lg:space-x-6 lg:space-y-0">
          <li>
            <NavLink className={({ isActive }) => linkClass(isActive)} to="/mijn-profiel">
              Mijn profiel
            </NavLink>
          </li>
          <li>
            <button className="text-white" onClick={handleLogout}>
              Uitloggen
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};
