import { Component } from 'react';
import type { ErrorInfo, ReactNode } from 'react';
import { toast } from 'react-hot-toast';

interface IProps {
  children?: ReactNode;
  fallback: NonNullable<ReactNode> | null;
}

interface IState {
  error: Error | undefined;
}

class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { error: undefined };
  }

  public static getDerivedStateFromError(error: Error): IState {
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    toast.error(`Uncaught error: ${error} ${errorInfo}`);
  }

  public render(): ReactNode {
    const { state, props } = this;
    if (state.error) {
      return <h1>Sorry.. there was an error</h1>;
    }

    return props.children;
  }
}

export default ErrorBoundary;
