import { ComponentType, FC, lazy, LazyExoticComponent, ReactElement, Suspense } from 'react';

// Components
import Loader from './Loader';

type LoadableFunc = () => Promise<{ default: ComponentType<unknown> }>;
interface LoadableFallback {
  fallback: ReactElement | null;
}

/**
 * Usage:
 *  const Fallback = { fallback: <div>Loading...</div> };
 *  const User = Loadable(() => import('./user/User'), Fallback);
 */
const Loadable = (
  importFunc: LoadableFunc,
  cb: LoadableFallback = { fallback: <Loader /> }
): FC => {
  const LazyComponent: LazyExoticComponent<ComponentType<unknown>> = lazy(importFunc);

  const ReturnComponent = (props: Record<string, unknown>) => (
    <Suspense fallback={cb.fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );

  return ReturnComponent;
};

export default Loadable;
