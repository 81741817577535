import { FC } from 'react';
import { ReactComponent as LogoFNLI } from '../assets/logo-fnli.svg';
import { ReactComponent as LogoKNSV } from '../assets/logo-knsv.svg';

const Footer: FC = () => (
  <footer className="flex py-8 mt-16 bg-white">
    <div className="container flex flex-row items-center justify-between">
      <span className="font-bold">&copy; IMVO Convenant Voedingsmiddelen</span>
      <div className="flex flex-row space-x-4">
        <LogoFNLI />
        <LogoKNSV />
      </div>
    </div>
  </footer>
);

export default Footer;
